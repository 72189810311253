<template>
  <div>
    <div class="form-group row">
      <label
        class="col-md-2"
        :class="labelClass"
        :for="`input-control-for-${fieldName}`"
        >{{ fieldLabel }}</label
      >
      <div class="hiddentPasswordContainer col">
        <input
          :disabled="disabled"
          v-if="fieldType == 'text'"
          v-bind:value="localValue"
          v-on:input="$emit('input', $event.target.value)"
          v-cleave="cleave[fieldName]"
          :type="fieldType"
          class="form-control col passwordInput"
        />
        <input
          v-else
          :disabled="disabled"
          v-bind:value="localValue"
          v-on:input="$emit('input', $event.target.value)"
          :type="fieldType"
          class="form-control col passwordInput"
        />
        <i :class="eyeClass" @click="toggleVisiblity()"></i>
      </div>
    </div>

    <modal
      :name="'toggle-confidential-data' + this.fieldName"
      transition="pop-out"
      :width="400"
      :clickToClose="false"
      :focus-trap="true"
      :height="280"
    >
      <div class="box">
        <div class="" id="bp-left">
          <div class="partition" id="partition-verification">
            <h3 class="partition-title">Enter your password to proceed</h3>

            <div class="partition-form">
              <form autocomplete="false" @submit.prevent="requestData">
                <div class="col-md-11 form-container">
                  <div class="col-md-12">
                    <input
                      class="form-control"
                      v-model="verificationData.password"
                      type="password"
                      autocomplete="off"
                      placeholder="Password"
                    />
                  </div>
                  <div class="col-md-12">
                    <a
                      type="submit"
                      class="col-md-12 btn PasswordBtn"
                      @click="requestData"
                      :disabled="inValidInputs == true"
                    >
                      <span
                        ><i class="mr-2 fas fa-check-circle fa-fw"></i
                        >Submit</span
                      >
                    </a>
                  </div>
                  <div class="col-md-12">
                    <a
                      type="cancel"
                      class="col-md-12 btn PasswordBtn"
                      @click="cancelToggleData"
                    >
                      <span
                        ><i class="mr-2 fas fa-check-circle fa-fw"></i
                        >Cancel</span
                      >
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "ToggleConfidentailData",
  props: ["fieldName", "fieldLabel", "value", "labelClass"],
  data() {
    return {
      disabled: true,
      localValue: this.value,
      fieldType: "password",
      eyeClass: "far fa-eye",
      verificationData: {
        password: "",
        fieldName: this.fieldName,
      },
      cleave: {
        phone: { blocks: [20], numericOnly: true },
        sin: { blocks: [3, 3, 3], delimiter: " ", numericOnly: true },
        healthcard: { blocks: [4, 3, 3], delimiter: " ", numericOnly: false },
      },
      fieldSecured: false,
      modalName: "toggle-confidential-data" + this.fieldName,
    };
  },

  computed: {
    ...mapState(["patients"]),
    inValidInputs() {
      return this.requestData.password == null;
    },
  },

  methods: {
    ...mapActions({
      doVerification: "patients/showConfidentialData",
    }),

    requestData: function (e) {
      e.preventDefault();

      let vm = this;
      this.doVerification({
        password: this.verificationData.password,
        attribute: this.verificationData.fieldName,
      })
        .then(function (response) {
          vm.$modal.hide(vm.modalName);
          //set the input value to the response
          if (response.status == "OK") {
            if (vm.verificationData.fieldName == "sin") {
              vm.localValue = response.sin;
            }
            if (vm.verificationData.fieldName == "healthcard") {
              vm.localValue = response.healthcard;
            }
            vm.$emit("input", vm.localValue);
            vm.fieldSecured = true;
            vm.showHideData();
          } else {
            Swal.fire({
              title: "Error",
              text: "Error on getting the data",
              icon: "error",
            });
          }
        })
        .catch(function (err) {
          if (!err.accessDenied) {
            Swal.fire({
              title: "Wrong Password",
              text: err.message.error || err.message,
              icon: "error",
            });
          }
        });
    },

    toggleVisiblity: function () {
      //if it was called before show the related data
      if (this.fieldSecured) {
        this.showHideData();
        return;
      }

      this.$modal.toggle(this.modalName);
    },
    showHideData: function () {
      this.disabled = !this.disabled;
      this.fieldType = this.fieldType == "password" ? "text" : "password";
      this.eyeClass =
        this.eyeClass == "far fa-eye" ? "far fa-eye-slash" : "far fa-eye";
    },
    cancelToggleData: function (e) {
      e.preventDefault();
      (this.verificationData.password = ""), this.toggleVisiblity();
    },
  },
};
</script>
<style lang="scss">
.partition-form {
  margin-top: 20px;
}
.box {
  background: white;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  box-sizing: border-box;
  box-shadow: 0 0 40px black;
  color: #254b7b;
  font-size: 12px;
  a {
    color: #03a9f4;
    font-size: 15px;
  }
  .form-container {
    text-align: center;
    margin: 0 auto;
  }
  .PasswordBtn {
    background: white;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px;
    letter-spacing: 1px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    margin-top: 8px;
    color: #8b8c8d;
    cursor: pointer;
    border: 1px solid #d2ddec;
    text-transform: uppercase;
    transition: 0.1s all;
    font-size: 10px;
    &:hover {
      border-color: mix(#254b7b, black, 90%);
      color: mix(#8b8c8d, black, 80%);
    }
    &:active {
      background-color: #254b7b !important;
      border-color: #254b7b !important;
      color: white !important;
    }
    &:focus {
      border-color: #254b7b !important;
    }
    margin-top: 15px;
  }

  .partition {
    width: 100%;
    height: 100%;
    .partition-title {
      box-sizing: border-box;
      padding: 20px;
      padding-bottom: 0;
      width: 100%;
      text-align: center;
      letter-spacing: 1px;
      font-size: 20px;
      font-weight: 500;
    }
  }
  .large-btn {
    background: white;
    span {
      font-weight: 600;
    }
    &:hover {
      color: white !important;
    }
  }
}
.pop-out-enter-active,
.pop-out-leave-active {
  transition: all 0.5s;
}
.pop-out-enter,
.pop-out-leave-active {
  opacity: 0;
  transform: translateY(24px);
}
</style>
