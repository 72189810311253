<template>
  <div>
    <form @submit.prevent="updateProfile">
      <div class="row profile-outer-page-container">
        <div class="col-12 col-lg-8">
          <input-control
            v-model="(patientData.user || {}).first"
            required
            labelClass="col-md-3"
            type="horizontal"
            :disabled="user && user.isPatient"
            >First Name</input-control
          >
          <span
            class="hintText col-md-8 offset-0 offset-md-3"
            v-if="user && user.isPatient"
          >
            To change this field contact your administrator</span
          >
          <input-control
            v-model="(patientData.user || {}).last"
            required
            labelClass="col-md-3"
            type="horizontal"
            :disabled="user && user.isPatient"
            >Last Name</input-control
          >
          <span
            class="hintText col-md-8 offset-0 offset-md-3"
            v-if="user && user.isPatient"
          >
            To change this field contact your administrator</span
          >
          <input-control
            v-model="(patientData.user || {}).email"
            required
            labelClass="col-md-3"
            type="horizontal"
            control="email"
            >Email</input-control
          >
          <input-control
            v-model="(patientData.user || {}).phone_number"
            type="horizontal"
            labelClass="col-md-3"
            :cleave="cleave.phone"
            >Phone Number</input-control
          >

          <div class="form-group row">
            <label class="col-md-3" for="input-control-birth_date"
              >Birth Date</label
            >
            <div class="col">
              <datetime
                id="input-control-birth_date"
                v-model="(patientData.user || {}).birth_date"
                placeholder=""
                :week-start="7"
                zone="UTC"
                format="yyyy-MM-dd"
                input-class="form-control"
              ></datetime>
            </div>
          </div>
          <select-control
            type="horizontal"
            v-model="(patientData.user || {}).gender"
            labelClass="col-md-3"
            :options="genderOptions"
            >Gender</select-control
          >

          <select-control
            v-if="user && (user.isAdmin || user.isManagingAdmin)"
            v-model="(patientData.user || {}).status"
            :options="statuses"
            required
            labelClass="col-md-3 col-12"
            >Status</select-control
          >

          <div class="form-group row" v-if="user && !user.isPatient">
            <label class="col-md-2 col-form-label col-md-3">HCAI Active?</label>
            <div class="col">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="hcai_active"
                  v-model="patientData.hcai_active"
                  @change="$forceUpdate()"
                />
                <label
                  class="custom-control-label pointer-cursor"
                  for="hcai_active"
                  >{{ patientData.hcai_active ? "Active" : "Inactive" }}</label
                >
              </div>
            </div>
          </div>

          <div
            class="form-group row"
            v-if="patientData.hcai_active && user && !user.isPatient"
          >
            <label class="col-md-2 col-form-label col-md-3">HCAI CAT?</label>
            <div class="col">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="hcai_cat"
                  v-model="patientData.hcai_cat"
                  @change="$forceUpdate()"
                />
                <label
                  class="custom-control-label pointer-cursor"
                  for="hcai_cat"
                  >{{ patientData.hcai_cat ? "Yes" : "No" }}</label
                >
              </div>
            </div>
          </div>

          <ToggleConfidentailData
            v-if="user && !user.isPatient"
            fieldName="healthcard"
            labelClass="col-md-3"
            fieldLabel="Health Card"
            v-bind:value="patientData.healthcard"
            v-on:input="
              (patientData.healthcard = $event && $event.replaceAll(' ', '')),
                (healthcardToggled = true)
            "
          />
          <input-control
            v-model="patientData.insurer"
            type="horizontal"
            labelClass="col-md-3"
            >Insurer</input-control
          >

          <div
            class="form-group row"
            v-if="user && (user.isAdmin || user.isManagingAdmin)"
          >
            <label
              class="administrator-id-label col-md-3 col-form-label"
              for="administrator_ids"
              >Client Administrator</label
            >
            <div class="col">
              <vue-tags-input
                id="administrator_ids"
                name="administrator_ids"
                class="form-control"
                v-model="tag"
                :tags="adminTags"
                :autocomplete-items="filteredItems"
                :add-only-from-autocomplete="true"
                :autocomplete-min-length="0"
                placeholder=""
                @tags-changed="(newTags) => (adminTags = newTags)"
              />
            </div>
          </div>

          <ToggleConfidentailData
            v-if="user && !user.isPatient"
            labelClass="col-md-3"
            fieldName="sin"
            fieldLabel="S.I.N"
            v-bind:value="patientData.sin"
            v-on:input="
              (patientData.sin = $event && $event.replaceAll(' ', '')),
                (sinToggled = true)
            "
          />

          <input-control
            v-model="patientData.interest_percentage"
            v-if="user && (user.isAdmin || user.isManagingAdmin)"
            labelClass="col-md-3"
            type="horizontal"
            control="number"
            step="0.01"
            min="0"
            >Interest Percentage (%)</input-control
          >
          <div
            class="form-group row"
            v-if="user && (user.isAdmin || user.isManagingAdmin)"
          >
            <label class="col-md-2 col-form-label col-md-3"
              >Interest Compounded?</label
            >
            <div class="col">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="is-interest-compounded"
                  v-model="patientData.is_compound"
                  @change="$forceUpdate()"
                />
                <label
                  class="custom-control-label pointer-cursor"
                  for="is-interest-compounded"
                  >{{ patientData.is_compound ? "Yes" : "No" }}</label
                >
              </div>
            </div>
          </div>

          <div class="form-group row" v-if="user && !user.isPatient">
            <label for class="col-md-3 col-form-label">Funding Source</label>
            <div class="col">
              <select
                v-model="patientData.funding_source"
                class="form-control"
                required
              >
                <option
                  v-for="p in fundingSourceOptions"
                  :key="p.value"
                  :value="p.value"
                >
                  {{ p.label }}
                </option>
              </select>
            </div>
          </div>
          <input-control
            v-if="user.email == 'karam@raysolutions.co'"
            type="horizontal"
            labelClass="col-md-3"
            v-model="patientData.referred_by"
            control="text"
            >Referred By</input-control
          >
          <div
            class="form-group row"
            v-if="user && (user.isAdmin || user.isManagingAdmin)"
          >
            <label class="col-md-3 col-form-label" for="referrers_ids"
              >Referred By</label
            >
            <div class="col">
              <vue-tags-input
                id="referrers_ids"
                name="referrers_ids"
                class="form-control"
                v-model="referrerTag"
                :tags="referrerTags"
                :autocomplete-items="referrersFilteredItems"
                :add-only-from-autocomplete="true"
                :autocomplete-min-length="0"
                placeholder=""
                @tags-changed="(newTags) => (referrerTags = newTags)"
              />
            </div>
          </div>
          <div class="form-group row" v-if="user && !user.isPatient">
            <label class="col-md-3" for="input-control-injured_at"
              >Date Of Injury</label
            >
            <div class="col">
              <datetime
                id="input-control-injured_at"
                v-model="patientData.injured_at"
                placeholder=""
                :week-start="7"
                zone="UTC"
                format="yyyy-MM-dd"
                input-class="form-control"
              ></datetime>
            </div>
          </div>
          <input-control
            type="horizontal"
            labelClass="col-md-3"
            v-model="patientData.claim_number"
            v-if="user && !user.isPatient"
            >Claim Number</input-control
          >
          <input-control
            type="horizontal"
            labelClass="col-md-3"
            v-model="patientData.policy_number"
            v-if="user && !user.isPatient"
            >Policy Number</input-control
          >
          <input-control
            v-if="user && !user.isPatient"
            type="horizontal"
            labelClass="col-md-3"
            v-model="(patientData.user || {}).bio"
            control="textarea"
            >Approved Hours</input-control
          >

          <div class="form-group row" v-if="user && !user.isPatient">
            <label class="col-md-2 col-form-label col-md-3"
              >Assessments Active?</label
            >
            <div class="col">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="assessment_active"
                  v-model="patientData.assessment_active"
                  @change="$forceUpdate()"
                />
                <label
                  class="custom-control-label pointer-cursor"
                  for="assessment_active"
                  >{{
                    patientData.assessment_active ? "Active" : "Inactive"
                  }}</label
                >
              </div>
            </div>
          </div>

          <input-control
            v-if="user && !user.isPatient && patientData.assessment_active"
            type="horizontal"
            labelClass="col-md-3"
            v-model="patientData.assessment"
            control="textarea"
            >Assessments</input-control
          >

          <div class="form-group row" v-if="user && !user.isPatient">
            <label class="col-md-2 col-form-label col-md-3"
              >Treatment Active?</label
            >
            <div class="col">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="treatment_active"
                  v-model="patientData.treatment_active"
                  @change="$forceUpdate()"
                />
                <label
                  class="custom-control-label pointer-cursor"
                  for="treatment_active"
                  >{{
                    patientData.treatment_active ? "Active" : "Inactive"
                  }}</label
                >
              </div>
            </div>
          </div>

          <input-control
            v-if="user && !user.isPatient && patientData.treatment_active"
            type="horizontal"
            labelClass="col-md-3"
            v-model="patientData.treatment"
            control="textarea"
            >Treatment</input-control
          >

          <div class="form-group row" v-if="user && !user.isPatient">
            <label class="col-md-2 col-form-label col-md-3"
              >Intervention Active?</label
            >
            <div class="col">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="intervention_active"
                  v-model="patientData.intervention_active"
                  @change="$forceUpdate()"
                />
                <label
                  class="custom-control-label pointer-cursor"
                  for="intervention_active"
                  >{{
                    patientData.intervention_active ? "Active" : "Inactive"
                  }}</label
                >
              </div>
            </div>
          </div>

          <input-control
            v-if="user && !user.isPatient && patientData.intervention_active"
            type="horizontal"
            labelClass="col-md-3"
            v-model="patientData.intervention"
            control="textarea"
            >Intervention/Plan</input-control
          >

          <div
            class="form-group row mt-5 pt-3 pl-3 pl-md-0"
            v-if="
              user &&
              user.isPatient &&
              patientData.user &&
              patientData.user.id == user.id
            "
          >
            <div class="col-lg-3"></div>
            <label class="ml-3 ml-lg-0 ml-xl-3 mr-xl-4 mb-0"
              >Receive Notifications Via</label
            >
            <div class="d-flex justify-content-between col mr-2">
              <div class="custom-control custom-switch ml-5 mr-2">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="sendingSMS"
                  v-model="(patientData.user || {}).enable_sending_sms"
                />
                <label
                  class="custom-control-label pointer-cursor"
                  for="sendingSMS"
                  >SMS</label
                >
              </div>
              <div class="custom-control custom-switch ml-2">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="sendingEmail"
                  v-model="(patientData.user || {}).enable_sending_email"
                />
                <label
                  class="custom-control-label pointer-cursor"
                  for="sendingEmail"
                  >Email</label
                >
              </div>
            </div>
          </div>

          <div
            class="offset-lg-3 col p-0 profile-action-container"
            v-if="
              user &&
              (user.isPatient || user.isAdmin || user.isManagingAdmin) &&
              $can({ key: 'clients', expectedPermission: 'edit' })
            "
          >
            <button
              class="mb-3 ml-lg-3 btn btn-theme"
              @click.prevent="$modal.show('change-password')"
            >
              <span
                ><i class="mr-2 fas fa-check-circle fa-key"></i>Update
                Password</span
              >
            </button>
            <save :saving="isSaving" classes="btn btn-black mb-3 ml-lg-3"
              >Update Profile &nbsp;&nbsp;&nbsp;&nbsp;</save
            >
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <AvatarForm
            @loaded="onLoad"
            :viewOnly="
              !(
                user.isAdmin ||
                user.isManagingAdmin ||
                user.id == patientData.user_id
              ) || !$can({ key: 'clients', expectedPermission: 'edit' })
            "
            v-bind:avatarPath="patientData.profile_picture"
          ></AvatarForm>
        </div>
      </div>
    </form>
    <changePasswordModal
      :userId="
        user && (user.isAdmin || user.isManagingAdmin)
          ? parseInt(patientData.user_id)
          : 0
      "
    />
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { Datetime } from "vue-datetime";
import ToggleConfidentailData from "./confidential-data-toggle.vue";
import VueTagsInput from "@johmun/vue-tags-input";
import changePasswordModal from "../../myProfile/update-password";
import Swal from "sweetalert2";
import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: "patientBasic",
  components: {
    datetime: Datetime,
    ToggleConfidentailData,
    VueTagsInput,
    changePasswordModal,
  },
  async mounted() {
    if (this.patientData && this.patientData.managers) {
      const userTags = [];
      this.patientData.managers.forEach((tag) => {
        userTags.push({ text: tag.name, value: tag.id, id: tag.id });
      });
      this.adminTags = userTags;
    } else {
      this.adminTags = [];
    }
    if (this.patientData && this.patientData.referredBy) {
      const referredByTags = [];
      this.patientData.referredBy.forEach((tag) => {
        referredByTags.push({ text: tag.name, value: tag.id, id: tag.id });
      });
      this.referrerTags = referredByTags;
    } else {
      this.referrerTags = [];
    }
    try {
      let users = [];
      const usersRes = await this.$http.get("admins-managers-info");
      usersRes.data.forEach((u) => {
        u = { text: u.name, value: u.id, id: u.id };
        users.push(u);
      });
      this.autocompleteItems = users;
    } catch (err) {
      //eslint-disable-next-line
      console.log(err);
    }

    // fill referral sources autocomplete items
    const _autocompleteItems = [];
    await this.getBasicReferralSources();
    this.allReferralSources.forEach((item) => {
      _autocompleteItems.push({
        text: item.name,
        value: item.id,
        id: item.id,
      });
    });
    this.referrersAutocompleteItems = _autocompleteItems;
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      patients: (state) => state.patients,
      originalPatientData: (state) => state.patients.patient,
      allReferralSources: (state) =>
        state.referralSources.allReferralSources.data,
      allReferralSourcesLoading: (state) =>
        state.referralSources.allReferralSources.loading,
    }),
    patientData: function () {
      return JSON.parse(JSON.stringify(this.originalPatientData));
    },
    id: function () {
      return this.$route.params.id;
    },
    filteredItems() {
      return this.autocompleteItems.filter((i) => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) != -1;
      });
    },
    referrersFilteredItems() {
      return this.referrersAutocompleteItems.filter((i) => {
        return (
          i.text.toLowerCase().indexOf(this.referrerTag.toLowerCase()) != -1
        );
      });
    },
  },
  data() {
    return {
      sinToggled: false,
      healthcardToggled: false,
      statuses: [
        { value: "ACTIVE", label: "Active" },
        { value: "PROTECTED", label: "Protected Account" },
        { value: "PENDING", label: "Pending" },
        { value: "ON_HOLD", label: "On Hold" },
        { value: "INACTIVE", label: "Inactive" },
      ],
      fundingSourceOptions: [
        { value: "government", label: "Government " },
        { value: "insurance_company", label: "Insurance company" },
        { value: "other", label: "Other" },
      ],
      genderOptions: [
        { value: "0", label: "Other" },
        { value: "1", label: "Male" },
        { value: "2", label: "Female" },
      ],
      sinFieldType: "password",
      sinEyeClass: "far fa-eye",
      hCardFieldType: "password",
      hCardEyeClass: "far fa-eye",
      cleave: {
        phone: { blocks: [20], numericOnly: true },
      },
      tag: "",
      adminTags: [],
      autocompleteItems: [],
      referrerTag: "",
      referrerTags: [],
      referrersAutocompleteItems: [],
      isSaving: false,
    };
  },
  methods: {
    ...mapActions({
      updatePatient: "patients/update",
      getBasicReferralSources: "referralSources/getBasicReferralSources",
    }),
    onLoad(avatar) {
      this.patientData.profile_picture = avatar.src;
      this.$store.dispatch("patients/updatePhoto", {
        profile_picture: avatar.file,
      });
    },
    toggleSIN: function () {
      this.sinFieldType = this.sinFieldType == "password" ? "text" : "password";
      this.sinEyeClass =
        this.sinEyeClass == "far fa-eye" ? "far fa-eye-slash" : "far fa-eye";
    },
    toggleHCard: function () {
      this.hCardFieldType =
        this.hCardFieldType == "password" ? "text" : "password";
      this.hCardEyeClass =
        this.hCardEyeClass == "far fa-eye" ? "far fa-eye-slash" : "far fa-eye";
    },
    updateProfile: function () {
      const sin = this.patientData.sin
        ? this.patientData.sin.replace(/[^0-9]\s+/gi, "")
        : "";
      if (sin.length && sin.length != 9 && sin != "*****") {
        return Swal.fire({
          title: "S.I.N Validation",
          text: "S.I.N should be 9 digits",
          icon: "error",
        });
      }
      this.isSaving = true;
      this.$forceUpdate();
      const data = {
        patient: this.patientData.id,
        first:
          !this.user || this.user.isPatient
            ? this.originalPatientData.user.first
            : this.patientData.user.first,
        last:
          !this.user || this.user.isPatient
            ? this.originalPatientData.user.last
            : this.patientData.user.last,
        phone_number: this.patientData.user.phone_number,
        injured_at: this.patientData.injured_at
          ? this.patientData.injured_at
          : null,
        referred_by: this.patientData.referred_by,
        status: this.patientData.user.status,
        funding_source: this.patientData.funding_source,
        diagnosis: this.patientData.diagnosis,
        claim_number: this.patientData.claim_number,
        policy_number: this.patientData.policy_number,
        insurer: this.patientData.insurer,
        hcai_active: this.patientData.hcai_active,
        hcai_cat: this.patientData.hcai_active
          ? this.patientData.hcai_cat
          : false,
        email: this.patientData.user.email,
        assessment: this.patientData.assessment,
        treatment: this.patientData.treatment,
        intervention: this.patientData.intervention,
        assessment_active: this.patientData.assessment_active,
        treatment_active: this.patientData.treatment_active,
        intervention_active: this.patientData.intervention_active,
      };
      if (this.healthcardToggled) {
        data.healthcard = this.patientData.healthcard
          ? this.patientData.healthcard.replace(/[^0-9]\s+/gi, "")
          : "";
      }
      if (this.sinToggled) {
        data.sin = sin;
      }

      if (this.user.isAdmin || this.user.isManagingAdmin) {
        data.interest_percentage = this.patientData.interest_percentage || 0;
        data.is_compound = this.patientData.is_compound;
      }
      const userIds = [];
      this.adminTags.forEach((el) => {
        userIds.push(el.id);
      });
      data.administrator_ids = userIds;

      const referralSourcesIds = [];
      this.referrerTags.forEach((tag) => {
        referralSourcesIds.push(tag.id);
      });
      data.referredByIds = referralSourcesIds;

      let profileData = this.patientData.user;
      profileData.noSuccessMessage = true;
      profileData.gender = this.patientData.user.gender;
      this.$store
        .dispatch("Profile/update", profileData)
        .then((res) => {
          if (res) {
            this.updatePatient(data).then(() => {
              this.isSaving = false;
              this.$forceUpdate();
              this.$store.dispatch("patients/get", {
                id: this.id,
                noLoading: true,
                sin,
                healthcard: data.healthcard,
              });
            });
          } else {
            this.isSaving = false;
            this.$forceUpdate();
          }
        })
        .catch(() => {
          this.isSaving = false;
          this.$forceUpdate();
        });
    },
  },
};
</script>
<style scoped lang="scss">
.hintText {
  display: block;
  margin-top: -1.375rem;
  margin-bottom: 1.375rem;
}
#input-control-enable-second-verification {
  transform: scale(0.5);
  width: 45px;
}
.col-md-1.row {
  min-width: 100px;
}
</style>
<style lang="scss">
#administrator_ids,
#referrers_ids {
  min-height: 4px;
  padding: 0;
  .ti-input {
    border: 0;
    .ti-tags {
      li {
        height: 32px;
        margin-top: 0;
      }
    }
  }
  .ti-new-tag-input-wrapper input {
    height: 100%;
  }
}
@media (max-width: 1024px) {
  .profile-outer-page-container {
    flex-direction: column-reverse;
  }
  .profile-action-container {
    display: flex;
    flex-direction: column;
  }
}
</style>
